import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Container } from '@mui/material';
import '../styles/OrderBook.css';
import { formatPrice } from '../utils/formatPrice'; // Adjust the path as needed

const OrderBook = ({ coin }) => {
  const [sellData, setSellData] = useState([]);
  const [buyData, setBuyData] = useState([]);
  const [base, setBase] = useState('GALA'); // Set default base
  const [quote, setQuote] = useState(coin); // Set default quote
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    // Determine base and quote whenever coin changes
    if (['GUSDT', 'GUSDC', 'GWETH'].includes(coin)) {
      setQuote('GALA');
      setBase(coin);
    } else {
      setQuote(coin);
      setBase('GALA');
    }
  }, [coin]);

  const fetchData = () => {
    axios.get(`${backendUrl}/api/data/orders?coin=${coin}`, {
      headers: {
        'Cache-Control': 'no-cache'
      }
    })
    .then(response => {
      console.log('API Response:', response.data);
      const sellData = response.data.filter(row => row.Action === 'SELL').slice(-6);
      const buyData = response.data.filter(row => row.Action === 'BUY').slice(0, 6);
      setSellData(sellData);
      setBuyData(buyData);
    })
    .catch(error => console.error('Error fetching data:', error));
  };

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(fetchData, 60000); // Fetch data every 60 seconds
    return () => clearInterval(intervalId);
  }, [coin]);

  useEffect(() => {
    const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    if (isDarkMode) {
      document.documentElement.classList.add('dark');
    }
  }, []);

  return (
    <Container className="container">
      <div className="titleContainer">
        <Typography variant="h4" gutterBottom className="title">
          📜 Order Book
        </Typography>
      </div>
      <TableContainer component={Paper} className="tableContainer">
        <Table className="table">
          <TableHead>
            <TableRow>
              <TableCell className="tableHeadCell">Price ({base})</TableCell>
              <TableCell className="tableHeadCell">Qty ({quote})</TableCell>
              <TableCell className="tableHeadCell">Qty ({base})</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sellData.map((row, rowIndex) => (
              <TableRow key={rowIndex} className="tableRow">
                <TableCell className={`tableCell sellCell`}>{formatPrice(row[`Price (${base})`])}</TableCell>
                <TableCell className="tableCell">{formatPrice(row[`Qty (${quote})`])}</TableCell>
                <TableCell className="tableCell">{formatPrice(row[`Qty (${base})`])}</TableCell>
              </TableRow>
            ))}
            {buyData.map((row, rowIndex) => (
              <TableRow key={rowIndex} className="tableRow">
                <TableCell className={`tableCell buyCell`}>{formatPrice(row[`Price (${base})`])}</TableCell>
                <TableCell className="tableCell">{formatPrice(row[`Qty (${quote})`])}</TableCell>
                <TableCell className="tableCell">{formatPrice(row[`Qty (${base})`])}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default OrderBook;
